<template>
  <div v-if="currentUser">
    <v-row align="center" justify="center">
      <v-col>
        <div style="flex-grow: 1;">
          <v-form id="guestform" ref="guestform" v-model="valid">
            <v-text-field
              class="mt-4"
              v-model="guestform.fname"
              name="fname"
              label="First Name"
              type="text"
              :rules="[rules.required]"
              required
              outlined
              rounded
              dense
            />
            <v-text-field
              v-model="guestform.lname"
              name="lname"
              label="Last Name"
              type="text"
              :rules="[rules.required]"
              required
              outlined
              rounded
              dense
            />

            <v-select
              v-model="guestform.medicalcard.gender"
              :items="genderSelect"
              item-text="gender"
              item-value="gender"
              label="Gender"
              :rules="[rules.required]"
              required
              rounded
              dense
              outlined
              return-object
            ></v-select>

            <v-dialog
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
              max-width="290px"
              @change="getAge()"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="tempBirthday"
                  append-icon="mdi-calendar"
                  label="Birthdate"
                  :rules="[rules.required]"
                  required
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="tempBirthday"
                :max="new Date().toISOString().substr(0, 10)"
                min="1900-01-01"
                label="Birthdate"
                ><v-btn text color="primary" @click="saveBirthday()"
                  >Save</v-btn
                ></v-date-picker
              >
            </v-dialog>
            <v-text-field
              v-model="guestform.email"
              label="Email (optional)"
              dense
              outlined
              rounded
              persistent-hint
              hint="Patient will not have access to patient portal without email address"
            ></v-text-field>
            <v-text-field
              v-model="guestform.phoneNumber"
              name="phoneNumber"
              label="Phone Number (optional)"
              type="text"
              :rules="phoneRules"
              required
              outlined
              rounded
              dense
            />

            <v-dialog
              ref="docmenu"
              v-model="docmenu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="350px"
              max-width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDoctor.displayName"
                  append-icon="mdi-stethoscope"
                  label="Choose a doctor"
                  :rules="[rules.required]"
                  required
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </template>
              <v-card>
                <v-card-title>Doctors</v-card-title>
                <v-data-iterator :items="doctors" :search="search">
                  <template v-slot:header>
                    <v-text-field
                      class="ma-2"
                      v-model="search"
                      clearable
                      flat
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                    ></v-text-field>
                  </template>

                  <template v-slot:default="props">
                    <v-list two-line>
                      <v-list-item
                        v-for="doctor in props.items"
                        :key="doctor.id"
                        @click="setDoctor(doctor)"
                      >
                        <v-list-item-avatar>
                          <img
                            v-if="!doctor.photoURL"
                            src="@/assets/avatar.png"
                          />
                          <img v-else :src="doctor.photoURL" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="doctor.displayName"
                          ></v-list-item-title>

                          <v-list-item-subtitle
                            v-text="doctor.specialty.name"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-data-iterator>
              </v-card>
            </v-dialog>

            <v-chip v-if="error" color="red" class="mb-4" label outlined>{{
              error
            }}</v-chip>

            <v-btn
              :disabled="!valid || !selectedDoctor"
              :block="$mq === 'mobile'"
              color="primary"
              :loading="loading"
              @click="addPatient"
              style="float: right;"
              >Register Patient</v-btn
            >
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as fb from '@/firebase'
import logoUH from '@/assets/logo-white.png'
import logoVP from '@/assets/logo-white.png'
import moment from 'moment'

export default {
  name: 'AddPatientDialog',
  props: ['user'],
  data() {
    return {
      logoUH: logoUH,
      logoVP: logoVP,
      siteMode: process.env.VUE_APP_SITE,
      valid: false,
      loading: false,
      error: null,
      hidePassword: true,
      menu: false,
      tempBirthday: null,
      doctors: [],
      docmenu: false,
      selectedDoctor: { displayName: '' },
      itemsPerPageArray: [4, 8, 12],
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      sortBy: 'specialty',
      itemsPerPage: 'All',
      genderSelect: [{ gender: 'Male' }, { gender: 'Female' }],
      photoURL: null,
      guestform: {
        photoURL: null,
        fname: '',
        lname: '',
        email: '',
        phoneNumber: '',
        password: '',
        medicalcard: {
          age: '',
          birthdate: '',
          gender: '',
          height: '',
          weight: '',
          address: '',
          pharmacy: ''
        }
      },
      displayName: '',
      rules: {
        required: value => !!value || 'Required.'
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      phoneRules: [
        value => !!value || !value,
        value => {
          const pattern = /^\d{3}-\d{3}-\d{4}$/
          const pattern2 = /^\d{10}$/
          const pattern3 = /^\s*$/
          return (
            pattern.test(value) ||
            pattern2.test(value) ||
            pattern3.test(value) ||
            'Invalid phone number'
          )
        }
      ],
      currentUser: null
    }
  },
  watch: {
    user: {
      immediate: true,
      async handler() {
        if (this.user) {
          this.currentUser = this.user
          await this.$bind(
            'doctors',
            fb.db
              .collection(`Proxies`)
              .where('role', '==', 'Doctor')
              .where('active', '==', true)
          )

          this.selectedDoctor = this.doctors.find(x => x.id === this.user.id)
            ? this.doctors.find(x => x.id === this.user.id)
            : { displayName: '' }
        }
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  methods: {
    setDoctor(doctor) {
      this.selectedDoctor = { ...doctor, id: doctor.id }
      this.docmenu = false
    },
    saveBirthday() {
      this.getAge()
      this.menu = false
    },
    getAge() {
      var birthday = this.tempBirthday
      const age = moment().diff(birthday, 'years')
      this.guestform.medicalcard.age = age
      this.guestform.medicalcard.birthdate = this.tempBirthday
    },

    async addPatient() {
      this.loading = true
      if (this.$refs.guestform.validate()) {
        this.password = this.generatePassword()
        const registerPatient = fb.functions.httpsCallable('registerPatient')
        let available = await this.checkExists()
        if (available) {
          try {
            await registerPatient({
              practiceId: this.selectedDoctor.practiceId,
              fname: this.guestform.fname,
              lname: this.guestform.lname,
              email: this.guestform.email ? this.guestform.email : 'anonymous',
              doctorId: this.selectedDoctor.id,
              birthdate: this.guestform.medicalcard.birthdate,
              gender: this.guestform.medicalcard.gender.gender,
              onboardingComplete: true,
              primaryDoctor: this.selectedDoctor.displayName,
              phoneNumber: this.guestform.phoneNumber
                ? this.guestform.phoneNumber
                : '',
              code: this.password
            }).then(async result => {
              await this.$emit(`newPatientId`, result.data.data.id)
              this.$refs.guestform.reset()
              this.loading = false
              this.$emit(`close`)
            })
          } catch (error) {
            this.error = error
            console.log(error)
            this.loading = false
          }
        } else {
          this.error = 'This patient already has an account.'
          this.loading = false
        }
      } else {
        this.error = 'All Fields Required'
      }
    },
    async checkExists() {
      const patientQuery = await fb.db
        .collection('Patients')
        .where('email', '==', this.guestform.email)
        .get()
      let available = false
      if (patientQuery.empty) {
        available = true
      }
      return available
    },
    generatePassword() {
      const length = 12
      var result = ''
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    uploadFile(file) {
      if (!file) return

      const reader = new FileReader()

      reader.onload = event => {
        this.guestform.photoURL = event.target.result
      }

      reader.readAsDataURL(file)

      const storageRef = fb.storage.ref()
      const photoRef = storageRef.child(`Patients/photos/${file.name}`)
      const uploadTask = photoRef.put(file)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          this.photoURL = downloadURL
        }
      )
    }
  }
}
</script>

<style scoped>
#form {
  margin-top: 0;
}
.upload-photo-icon {
  position: absolute;
  bottom: 0%;
  right: 36%;
}

.container {
  max-width: 737px !important;
}

.signup-container {
  max-width: 737px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.signup-card {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 100px;
}
.v-btn {
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .upload-photo-icon {
    position: absolute;
    bottom: 0%;
    right: 18%;
  }
}
</style>

<template>
  <div>
    <v-stepper v-model="e1" non-linear>
      <v-stepper-header style="flex-wrap: nowrap;" class="align-center">
        <v-stepper-step editable step="1">
          Create Appointment
        </v-stepper-step>

        <v-divider v-if="selectedPartner"></v-divider>

        <v-stepper-step v-if="selectedPartner" editable step="2">
          Electronic Order
        </v-stepper-step>
        <v-btn class="mr-6" @click="close()" small text color="error"
          >Cancel</v-btn
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card max-width="500px">
            <v-container>
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selectedDoctor"
                      :items="doctors"
                      item-text="Name"
                      item-value="id"
                      label="Doctor"
                      return-object
                      hide-details="hint"
                      dense
                      outlined
                      rounded
                      required
                      :rules="rules"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      ref="patientSelect"
                      v-model="selectedPatient"
                      :items="patients"
                      item-text="displayName"
                      item-value="id"
                      label="Patient"
                      return-object
                      hide-details="hint"
                      dense
                      rounded
                      outlined
                      required
                      :rules="rules"
                    >
                      <template v-slot:item="{ item }">
                        <!-- HTML that describe how select should render items when the select is open -->
                        <v-list-item link @click="setPatient(item)" two-line>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.displayName }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              >DOB: {{ item.dob }} -
                              {{ item.email }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <div style="width: 100%;" class="d-flex justify-center">
                    <v-btn
                      x-small
                      text
                      color="primary"
                      @click="addPatientDialog = true"
                      >Add New Patient</v-btn
                    >
                  </div>

                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      ref="partnerSelect"
                      v-model="selectedPartner"
                      :items="partnersList"
                      item-text="data"
                      item-value="id"
                      label="Partner"
                      return-object
                      :hint="
                        !doctorHasSignature
                          ? 'Doctor signature required'
                          : 'Required if no patient email or phone number provided.'
                      "
                      persistent-hint
                      :disabled="!doctorHasSignature"
                      dense
                      rounded
                      outlined
                      :rules="
                        selectedPatient &&
                        selectedPatient.email == 'anonymous' &&
                        !selectedPatient.phoneNumber
                          ? rules2
                          : [true]
                      "
                      :required="
                        selectedPatient &&
                          selectedPatient.email == 'anonymous' &&
                          !selectedPatient.phoneNumber
                      "
                    >
                      <template v-slot:item="{ item }">
                        <!-- HTML that describe how select should render items when the select is open -->
                        <v-list-item
                          link
                          @click="setPartner(item)"
                          style="max-width: 500px"
                          two-line
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.name }}
                              {{ item.contact ? ` - ${item.contact}` : '' }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              >{{ item.phoneNumber ? item.phoneNumber : '' }}
                              {{ item.address ? ` - ${item.address}` : '' }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ item.email ? item.email : '' }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <div
                    style="width: 100%;"
                    v-if="currentUser.isAdmin"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      x-small
                      text
                      @click="addPartnerDialog = true"
                      color="primary"
                      >Add New Partner</v-btn
                    >
                  </div>

                  <v-col cols="12" md="12" v-if="selectedPartner">
                    <v-text-field
                      v-model="patientAddress"
                      label="Patient Address (optional)"
                      dense
                      outlined
                      rounded
                      hide-details="hint"
                    ></v-text-field>
                  </v-col>
                  <v-dialog
                    ref="dialog"
                    v-model="dialog"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <v-date-picker
                      v-model="date"
                      :min="new Date().toISOString().substr(0, 10)"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="cancel()">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="computedDateFormattedMomentjs"
                      :value="computedDateFormattedMomentjs"
                      append-icon="event"
                      label="Date"
                      dense
                      outlined
                      rounded
                      hide-details="hint"
                      :rules="rules"
                      @click:append="dialog = true"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      v-model="startTime"
                      :items="times"
                      :rules="rules"
                      item-text="time"
                      item-value="time"
                      label="Start Time"
                      rounded
                      outlined
                      dense
                      required
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      v-model="endTime"
                      :items="times2"
                      :rules="rules"
                      label="End Time"
                      rounded
                      outlined
                      dense
                      required
                      disabled
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-checkbox
                      v-model="firstTime"
                      label="First Time Appointment?"
                      hide-details="messages"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-btn
                  v-if="!selectedPartner"
                  @click="createAppt"
                  :disabled="!valid"
                  color="primary"
                  block
                  >Create</v-btn
                >

                <v-btn
                  v-else
                  @click="e1 = 2"
                  :disabled="!valid"
                  color="primary"
                  block
                  >Next</v-btn
                >
              </v-form>
            </v-container>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card max-width="500px">
            <v-container>
              <v-form ref="form2" v-model="valid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="form2.isElectronicOrder"
                      label="VPExam Electronic Order"
                      hide-details="messages"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="form2.isHomeCareOrder"
                      label="VPExam Homecare Order"
                      hide-details="messages"
                    ></v-checkbox>
                  </v-col>
                  <v-dialog
                    ref="dialog2"
                    v-model="dialog2"
                    :return-value.sync="date2"
                    persistent
                    width="290px"
                  >
                    <v-date-picker v-model="date2" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="cancel()">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog2.save(date2)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="computedDate2FormattedMomentjs"
                      :value="computedDate2FormattedMomentjs"
                      append-icon="event"
                      label="Date of Encounter"
                      dense
                      outlined
                      rounded
                      hide-details="hint"
                      :rules="rules"
                      @click:append="dialog2 = true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-checkbox
                      v-model="form2.isFace2Face"
                      label="Face to Face Encounter was completed"
                      hide-details="messages"
                    ></v-checkbox>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="12">
                    <v-card-title class="ma-0 pa-0">
                      Skilled Discipline Ordered
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-checkbox
                      v-model="form2.isNursing"
                      label="Skilled Nursing (RN/LPN)"
                      hide-details="messages"
                      @change="updateFindings()"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-checkbox
                      class="mt-0"
                      v-model="form2.isHomeAide"
                      label="Home Health Aide"
                      hide-details="messages"
                      @change="updateFindings()"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="form2.diagnosis"
                      label="Medical Diagnosis"
                      dense
                      outlined
                      rounded
                      hide-details="hint"
                      :rules="rules"
                      @change="updateFindings()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-checkbox
                      v-model="form2.isNecessity"
                      label="Medical Necessity for Homecare (based on clinical findings): "
                      hide-details="messages"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      outlined
                      v-model="form2.homecare"
                      label="Clinical Findings"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-checkbox
                      v-model="form2.isHomeBound"
                      label="Homebound Status"
                      hide-details="messages"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      outlined
                      v-model="form2.homeBoundStatus"
                      label="Clinical Findings"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-checkbox
                      v-model="form2.isFace2FaceReviewed"
                      label="Face to Face Completed and Home Care Orders Reviewed"
                      hide-details="messages"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      outlined
                      v-model="form2.homeCareReviewed"
                      label="Home Care Orders Reviewed"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-btn @click="e1 = 1" text color="primary">
                  Back
                </v-btn>
                <v-btn
                  @click="createAppt"
                  :disabled="!valid || !selectedPartner"
                  color="primary"
                  >Create</v-btn
                >
              </v-form>
            </v-container>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog
      v-if="addPatientDialog"
      max-width="600px"
      v-model="addPatientDialog"
    >
      <v-card>
        <v-card-title>
          Add Patient
          <v-spacer></v-spacer>
          <v-btn color="error" @click="addPatientDialog = false" text
            >Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <AddPatientDialog
            @close="addPatientDialog = false"
            @newPatientId="setNewPatient"
            :user="currentUser"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="addPartnerDialog"
      max-width="600px"
      v-model="addPartnerDialog"
    >
      <v-card>
        <v-card-title>
          Add Partner
          <v-spacer></v-spacer>
          <v-btn color="error" @click="addPartnerDialog = false" text
            >Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <AddPartnerDialog
            @close="addPartnerDialog = false"
            @newPartnerId="setNewPartner"
            :user="currentUser"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as fb from '@/firebase'
import * as moment from 'moment'
import AddPatientDialog from '@/components/dialogs/AddPatientDialog'
import AddPartnerDialog from '@/components/dialogs/AddPartnerDialog'
// import { patientsCollection } from '../firebase'
export default {
  name: 'AddAppointment',
  props: ['user'],
  components: {
    AddPatientDialog,
    AddPartnerDialog
  },
  data() {
    return {
      e1: 1,
      currentUser: null,
      selectedPatient: null,
      selectedDoctor: null,
      selectedPartner: null,
      addPatientDialog: false,
      addPartnerDialog: false,
      url: '',
      path: '',
      practice: {
        id: ''
      },
      patientAddress: '',
      firstTime: false,
      firstAppt: 15,
      returningAppt: 30,
      adHoc: false,
      patients: [],
      partners: [],
      partnersList: [],
      doctors: [],
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      dateNoDash: null,
      dialog: false,
      dialog2: false,
      valid: false,
      repeat: true,
      name: 'Office Hours',
      startTime: null,
      endTime: null,
      endFifteen: '',
      endThirty: '',
      start: '',
      end: '',
      rules: [v => !!v || 'Required'],
      rules2: [
        v => !!v || 'Required if no patient email or phone number provided.'
      ],
      patientsCollection: [],
      doctorHasSignature: false,
      form2: {
        isElectronicOrder: true,
        isHomeCareOrder: true,
        isFace2Face: true,
        isNursing: true,
        isHomeAide: false,
        isNecessity: true,
        diagnosis: '',
        homecare: '',
        isHomeBound: true,
        isFace2FaceReviewed: true,
        homeBoundStatus: '',
        homeCareReviewed: ''
      },
      appointmentId: '',
      newPartnerId: null,
      newPatientId: null
    }
  },
  watch: {
    user: {
      immediate: true,
      async handler() {
        if (this.user) {
          this.currentUser = this.user
          this.practice.id = this.user.practiceId

          if (this.user.firstAppt) {
            this.firstAppt = this.user.firstAppt
          } else {
            this.firstAppt = 15
          }

          if (this.user.returningAppt) {
            this.returningAppt = this.user.returningAppt
          } else {
            this.returningAppt = 30
          }

          await this.$bind(
            'patientsCollection',
            fb.db.collection('Patients').where(this.practice.id, '==', true)
          )
        }
      }
    },
    selectedDoctor: {
      immediate: true,
      handler() {
        if (this.selectedDoctor) {
          if (!this.selectedDoctor.signature) {
            this.doctorHasSignature = false
          } else {
            this.doctorHasSignature = true
          }
        }
      }
    },
    patientsCollection: {
      immediate: true,
      handler() {
        if (this.patientsCollection) {
          this.patients = []
          for (const item of this.patientsCollection) {
            const displayName = item.fname + ' ' + item.lname
            this.patients.push({
              id: item.id,
              displayName: displayName,
              dob: moment(item.medicalcard.birthdate.toDate()).format(
                'MM-DD-YYYY'
              ),
              ...item
            })
          }
          if (this.newPatientId) {
            let index = this.patients.findIndex(x => x.id == this.newPatientId)
            if (index !== -1) {
              this.selectedPatient = this.patients[index]
              this.newPatientId = null
            }
          }
        }
      }
    },
    startTime: function() {
      this.dateNoDash = this.date.split('-').join('/')
      this.endFifteen = moment(
        new Date(`${this.dateNoDash} ${this.startTime}`)
      ).add(this.returningAppt, 'm')
      this.endThirty = moment(
        new Date(`${this.dateNoDash} ${this.startTime}`)
      ).add(this.firstAppt, 'm')

      if (this.firstTime) {
        this.endTime = this.endThirty.format('hh:mm A')
      } else {
        this.endTime = this.endFifteen.format('hh:mm A')
      }
    },
    firstTime: function() {
      if (this.firstTime) {
        this.endTime = this.endThirty.format('hh:mm A')
      } else {
        this.endTime = this.endFifteen.format('hh:mm A')
      }
    },
    partners: {
      immediate: true,
      handler() {
        if (this.partners) {
          this.partnersList = []
          for (const item of this.partners) {
            const data = `${item.name} - ${item.email}`
            this.partnersList.push({ ...item, id: item.id, data: data })
          }
          if (this.newPartnerId) {
            let index = this.partnersList.findIndex(
              x => x.id == this.newPartnerId
            )
            if (index !== -1) {
              this.selectedPartner = this.partnersList[index]
              this.newPartnerId = null
            }
          }
        }
      }
    }
  },
  computed: {
    computedDateFormattedMomentjs: {
      get() {
        return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : ''
      },
      set() {}
    },
    computedDate2FormattedMomentjs: {
      get() {
        return this.date2 ? moment(this.date2).format('dddd, MMMM Do YYYY') : ''
      },
      set() {}
    },
    computedStartTime: {
      get() {
        return this.start
          ? moment(this.start.toDate()).format('MMMM D, YYYY hh:mm a')
          : ''
      },
      set() {}
    },
    computedEndTime: {
      get() {
        return this.end ? moment(this.end.toDate()).format('hh:mm a') : ''
      },
      set() {}
    },
    times: function() {
      const times = []
      const timesLimitted = []

      if (moment(this.date).isSame(moment(), 'day')) {
        // const secondsInDay = 86400
        const steps = 60 * this.returningAppt // 15 minutes
        const duration = moment.duration(
          moment()
            .endOf('day')
            .diff(moment())
        )
        const seconds = duration.asSeconds()
        const startingHour = moment().hour()
        const diff =
          this.returningAppt - (moment().minute() % this.returningAppt)
        const startingMinutes = moment()
          .add(diff, 'minutes')
          .minutes()

        for (let i = 0; i < seconds; i += steps) {
          const d = moment(this.date).toDate()
          d.setHours(startingHour)
          d.setMinutes(startingMinutes)
          d.setSeconds(i)

          const time = moment(
            fb.db.app.firebase_.firestore.Timestamp.fromDate(d).toDate()
          ).format('hh:mm A')

          times.push(time)
        }

        const timesStart = 0
        let index = timesStart

        while (index !== times.length) {
          timesLimitted.push(times[index])
          index++
        }

        return timesLimitted
      } else {
        const secondsInDay = 86400
        const steps = 60 * this.returningAppt // 15 minutes

        for (let i = 0; i < secondsInDay; i += steps) {
          const d = new Date()
          d.setHours(0)
          d.setMinutes(0)
          d.setSeconds(i)

          const time = moment(
            fb.db.app.firebase_.firestore.Timestamp.fromDate(d).toDate()
          ).format('hh:mm A')

          times.push(time)
        }

        const timesStart = times.indexOf('07:00 AM')
        let index = timesStart

        while (index !== times.length) {
          timesLimitted.push(times[index])
          index++
        }

        return timesLimitted
      }
    },
    times2: function() {
      const times = []
      const timesLimitted = []
      const secondsInDay = 86400
      const steps = 60

      for (let i = 0; i < secondsInDay; i += steps) {
        const d = new Date()
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(i)

        const time = moment(
          fb.db.app.firebase_.firestore.Timestamp.fromDate(d).toDate()
        ).format('hh:mm A')

        times.push(time)
      }

      const timesStart = times.indexOf('07:00 AM')
      let index = timesStart

      while (index !== times.length) {
        timesLimitted.push(times[index])
        index++
      }

      return timesLimitted
    }
  },
  async mounted() {
    const doctors = await fb.proxiesCollection
      .where('practiceId', '==', this.practice.id)
      .where('role', '==', 'Doctor')
      .get()

    for (const item of doctors.docs) {
      const data = item.data()
      const displayName = 'Dr. ' + item.data().fname + ' ' + item.data().lname
      this.doctors.push({ id: item.id, Name: displayName, ...data })
    }

    this.selectedDoctor = this.doctors.find(x => x.id === this.currentUser.id)

    this.form2.homecare = `My clinical findings support the need for ${
      !this.form2.isNursing && !this.form2.isHomeAide ? ' [Aide]' : ''
    }${this.form2.isNursing ? ' Skilled Nursing' : ''}${
      this.form2.isHomeAide ? ' Home Health Aide' : ''
    } to monitor signs of adverse events from new ${
      this.form2.diagnosis ? this.form2.diagnosis : '[diagnosis]'
    }.  Short-term ${
      !this.form2.isNursing && !this.form2.isHomeAide ? ' [Aide]' : ''
    }${this.form2.isNursing ? ' Skilled Nursing' : ''}${
      this.form2.isHomeAide ? ' Home Health Aide' : ''
    } is needed to monitor for signs and symptoms of decompensation/adverse events. VPExam Virtual Care services will also be delivered to the patient in collaboration with${
      !this.form2.isNursing && !this.form2.isHomeAide ? ' [Aide]' : ''
    }${this.form2.isNursing ? ' Skilled Nursing' : ''}${
      this.form2.isHomeAide ? ' Home Health Aide' : ''
    }. VPExam and Short-term ${
      !this.form2.isNursing && !this.form2.isHomeAide ? ' [Aide]' : ''
    }${this.form2.isNursing ? ' Skilled Nursing' : ''}${
      this.form2.isHomeAide ? ' Home Health Aide' : ''
    } is needed to monitor for adverse events. Patient is at high risk for re-hospitalization. These services are medically necessary as the patient has significant increased symptoms and issues with med compliance.`

    this.form2.homeBoundStatus = `Patient with recent exacerbation of ${
      this.form2.diagnosis ? this.form2.diagnosis : '[diagnosis]'
    }. Patient experiences significant symptoms such as dyspnea with minimal exertion. Patient ambulates limited distance of 20-30 feet. Patient has poor endurance and requires use of additional assistance for safe ambulation in the home. The totality of these findings supports a considerable and taxing effort to leave home due to limited mobility and pain.  Based on my clinical findings, this patient is homebound due to extreme dyspnea limiting ambulation. `

    this.form2.homeCareReviewed = `I certify that this patient is under my care. I have reviewed the information included in the face to face and certify that the home care services ordered are medically necessary for this patient.VPExam Virtual Care Services are to be performed at the scheduled date and time in order to manage the patient’s care.`
  },
  async created() {
    await this.$bind('partners', fb.db.collection('Partners'))
  },
  methods: {
    close() {
      this.$emit('closeAddAppt')
    },
    setPatient(item) {
      this.selectedPatient = item
      this.$refs.patientSelect.isMenuActive = false
    },
    setPartner(item) {
      this.selectedPartner = item
      this.$refs.partnerSelect.isMenuActive = false
    },
    setNewPartner(id) {
      this.newPartnerId = id
    },
    setNewPatient(id) {
      this.newPatientId = id
    },
    updateFindings() {
      this.form2.homecare = `My clinical findings support the need for ${
        !this.form2.isNursing && !this.form2.isHomeAide ? ' [Aide]' : ''
      }${this.form2.isNursing ? ' Skilled Nursing' : ''}${
        this.form2.isHomeAide ? ' Home Health Aide' : ''
      } to monitor signs of adverse events from new ${
        this.form2.diagnosis ? this.form2.diagnosis : '[diagnosis]'
      }.  Short-term ${
        !this.form2.isNursing && !this.form2.isHomeAide ? ' [Aide]' : ''
      }${this.form2.isNursing ? ' Skilled Nursing' : ''}${
        this.form2.isHomeAide ? ' Home Health Aide' : ''
      } is needed to monitor for signs and symptoms of decompensation/adverse events. VPExam Virtual Care services will also be delivered to the patient in collaboration with${
        !this.form2.isNursing && !this.form2.isHomeAide ? ' [Aide]' : ''
      }${this.form2.isNursing ? ' Skilled Nursing' : ''}${
        this.form2.isHomeAide ? ' Home Health Aide' : ''
      }. VPExam and Short-term${
        !this.form2.isNursing && !this.form2.isHomeAide ? ' [Aide]' : ''
      }${this.form2.isNursing ? ' Skilled Nursing' : ''}${
        this.form2.isHomeAide ? ' Home Health Aide' : ''
      } is needed to monitor for adverse events. Patient is at high risk for re-hospitalization. These services are medically necessary as the patient has significant increased symptoms and issues with med compliance.`

      this.form2.homeBoundStatus = `Patient with recent exacerbation of ${
        this.form2.diagnosis ? this.form2.diagnosis : '[diagnosis]'
      }. Patient experiences significant symptoms such as dyspnea with minimal exertion. Patient ambulates limited distance of 20-30 feet. Patient has poor endurance and requires use of additional assistance for safe ambulation in the home. The totality of these findings supports a considerable and taxing effort to leave home due to limited mobility and pain.  Based on my clinical findings, this patient is homebound due to extreme dyspnea limiting ambulation. `
    },
    cancel() {
      this.$refs.form.reset()
      this.dialog = false
    },
    async createAppt() {
      if (this.$refs.form.validate()) {
        this.start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
          new Date(`${this.dateNoDash} ${this.startTime}`)
        )
        this.end = fb.db.app.firebase_.firestore.Timestamp.fromDate(
          new Date(`${this.dateNoDash} ${this.endTime}`)
        )

        try {
          const appointment = await fb.db
            .collection('Practices')
            .doc(this.practice.id)
            .collection('Appointments')
            .doc()
          const appId = appointment.id
          this.appointmentId = appId

          this.path = '?code=' + appId + '&p=' + this.practice.id

          this.url =
            process.env.VUE_APP_URL +
            'room?code=' +
            appId +
            '&p=' +
            this.practice.id

          appointment
            .set(
              {
                patientId: this.selectedPatient.id,
                doctorId: this.selectedDoctor.id,
                uniqueRoom: appId,
                url: this.url,
                path: this.path,
                immediate: false,
                firstTime: this.firstTime,
                adHoc: false,
                isWaiting: false,
                ekoConnected: false,
                recordTime: null,
                startRecEkoFromDoc: false,
                components: [],
                medicalHistory: [],
                medicalHistoryQuestions: null,
                vitals: {
                  painscale: null,
                  pulse: null,
                  pulseox: null,
                  resprate: null,
                  temperature: null,
                  systolic: null,
                  diastolic: null
                },
                isExamComplete: false,
                vitalStamp: null,
                created: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                  new Date()
                ),
                startTime: this.start,
                endTime: this.end,
                partnerId: this.selectedPartner
                  ? this.selectedPartner.id
                  : null,
                patientAddress: this.patientAddress ? this.patientAddress : ''
              },
              { merge: true }
            )
            .then(() => {
              if (this.selectedPartner) {
                const sendPartnerNotification = fb.functions.httpsCallable(
                  'sendPartnerNotification'
                )
                try {
                  sendPartnerNotification({
                    appointmentId: appId,
                    partnerId: this.selectedPartner.id,
                    partnerName: this.selectedPartner.name,
                    partnerEmail: this.selectedPartner.email,
                    isExpress: this.selectedPartner.isExpress,
                    practiceId: this.practice.id,
                    patientName: this.selectedPatient.displayName,
                    doctorName: this.selectedDoctor.displayName
                  })
                } catch (error) {
                  this.error = error
                  console.log(error)
                }
              }
            })
            .then(() => {
              if (this.selectedPartner) {
                try {
                  const order = fb.db.collection('Electronic_Orders').doc(appId)
                  order.set({
                    encounterDate: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                      new Date(`${this.date2} ${moment().format('hh:mm A')}`)
                    ),
                    isElectronicOrder: this.form2.isElectronicOrder,
                    isHomeCareOrder: this.form2.isHomeCareOrder,
                    isFace2Face: this.form2.isFace2Face,
                    isNursing: this.form2.isNursing,
                    isHomeAide: this.form2.isHomeAide,
                    isNecessity: this.form2.isNecessity,
                    diagnosis: this.form2.diagnosis,
                    homecare: this.form2.homecare,
                    isHomeBound: this.form2.isHomeBound,
                    isFace2FaceReviewed: this.form2.isFace2FaceReviewed,
                    homeBoundStatus: this.form2.homeBoundStatus,
                    homeCareReviewed: this.form2.homeCareReviewed,
                    practiceId: this.practice.id,
                    appointmentId: appId,
                    partnerId: this.selectedPartner.id,
                    patientId: this.selectedPatient.id,
                    doctorId: this.selectedDoctor.id,
                    doctorName: this.selectedDoctor.displayName,
                    date: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                      new Date()
                    ),
                    createdAt: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                      new Date()
                    ),
                    orderId: order.id
                  })
                } catch (error) {
                  this.error = error
                  console.log(error)
                }
              }
            })
        } catch (error) {
          this.error = error.message
          console.log(error)
        }

        this.sendEmailNotification()
        this.$emit('closeAddAppt')
      }
    },
    sendEmailNotification() {
      const appointmentCreatedEmailNotification = fb.functions.httpsCallable(
        'appointmentCreatedEmailNotification'
      )

      try {
        appointmentCreatedEmailNotification({
          email: this.selectedPatient.email,
          userId: this.selectedPatient.id,
          appointmentDate: `${this.computedStartTime} - ${this.computedEndTime}`,
          appointmentPath: this.path
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<template>
  <div>
    <v-row align="center" justify="center">
      <v-col>
        <div style="flex-grow: 1;">
          <v-form id="form" ref="form" lazy-validation v-model="valid">
            <v-text-field
              class="mt-4"
              v-model="form.name"
              name="name"
              label="Name"
              type="text"
              :rules="[rules.required]"
              required
              outlined
              rounded
              dense
            />
            <v-text-field
              v-model="form.email"
              label="Email"
              dense
              outlined
              rounded
              required
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              v-model="form.contact"
              name="contact"
              label="Contact Name (optional)"
              type="text"
              outlined
              rounded
              dense
            />
            <v-text-field
              v-model="form.phoneNumber"
              name="phoneNumber"
              label="Phone Number (optional)"
              type="text"
              :rules="phoneRules"
              required
              outlined
              rounded
              dense
            />

            <v-text-field
              v-model="form.address"
              name="address"
              label="Address (optional)"
              type="text"
              outlined
              rounded
              dense
            />

            <v-chip v-if="error" color="red" class="mb-4" label outlined>{{
              error
            }}</v-chip>

            <v-btn
              :disabled="!valid"
              :block="$mq === 'mobile'"
              color="primary"
              :loading="loading"
              lazy-validation
              @click="addPartner"
              style="float: right;"
              >Add Partner</v-btn
            >
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as fb from '@/firebase'

export default {
  name: 'AddPartnerDialog',
  props: ['user'],
  data() {
    return {
      valid: false,
      loading: false,
      error: null,
      form: {
        name: '',
        email: '',
        contact: '',
        phoneNumber: '',
        address: ''
      },
      password: '',
      rules: {
        required: value => !!value || 'Required.'
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      phoneRules: [
        value => !!value || !value,
        value => {
          const pattern = /^\d{3}-\d{3}-\d{4}$/
          const pattern2 = /^\d{10}$/
          const pattern3 = /^\s*$/
          return (
            pattern.test(value) ||
            pattern2.test(value) ||
            pattern3.test(value) ||
            'Invalid phone number'
          )
        }
      ]
    }
  },
  methods: {
    async addPartner() {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.password = this.generatePassword()
        const registerPartner = fb.functions.httpsCallable('registerPartner')
        let available = await this.checkExists()
        if (available) {
          try {
            await registerPartner({
              name: this.form.name,
              email: this.form.email,
              contact: this.form.contact,
              address: this.form.address,
              phoneNumber: this.form.phoneNumber,
              code: this.password
            }).then(result => {
              this.$emit(`newPartnerId`, result.data.data.id)
              this.$refs.form.reset()
              this.loading = false
              this.$emit(`close`)
            })
          } catch (error) {
            this.error = error.message
            this.loading = false
          }
        } else {
          this.error = 'This partner already has an account.'
          this.loading = false
        }
      } else {
        this.error = 'All Fields Required'
      }
    },
    async checkExists() {
      const partnerQuery = await fb.db
        .collection('Partners')
        .where('email', '==', this.form.email)
        .get()
      let available = false
      if (partnerQuery.empty) {
        available = true
      }
      return available
    },
    generatePassword() {
      const length = 12
      var result = ''
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    }
  }
}
</script>
